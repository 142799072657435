<template>
  <div v-if="sponsored" class="featured-icon">
    FEATURED
  </div>
</template>

<script>
export default {
  props: {
    sponsored: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
